<template>
<div>
    <v-dialog v-model="dialog" width="500">

        <v-card tile flat>
            <v-container>
                <v-toolbar color="warning" dark>
                    <v-toolbar-title>
                        <v-icon class="ml-2">info</v-icon><strong>تنبيه</strong>
                    </v-toolbar-title>
                </v-toolbar>
            </v-container>
            <v-col>
                <v-alert text  dense>
                <v-card-title>
                    <h4>{{data.text}} </h4>
                </v-card-title>
            </v-alert>
            </v-col>
            <v-card-actions>
                <v-btn @click="confirm(data.id)" color="primary" x-large class="mr-2 btn">تأكيد</v-btn>
                <v-btn @click="dialog = false" color="error" outlined x-large class="mr-2 btn">إلغاء</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            data: {
                id: '',
                text: '',
                code: ''
            }
        }
    },

    computed: {
        target() {
            return this.data.code
        }
    },

    methods: {
        confirm(id) {
            this.$service.eventBus.emit(this.target, {
                id: id,
                code: this.target
            })

            this.$eventBus.$emit(this.target, {
                id: id,
                code: this.target
            });

            this.dialog = false
        }

    },

    created() {
        this.$eventBus.$on('confirm', (data) => {
            this.dialog = true
            this.data = data
        });

        this.$service.eventBus.on('confirm', (data) => {
            this.dialog = true
            this.data = data
        });
    }

}
</script>
